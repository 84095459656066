<template>
  <div class="page">
    <div class="infoBox">
      <div class="left_box">
        <img
          :src="
            detail.imageUrl
              ? detail.imageUrl
              : require('@/assets/img/default_avatar.png')
          "
          alt=""
        />
      </div>
      <div class="right_box">
        <div class="top">
          <div class="name">{{ detail.title }}</div>
          <div class="type">{{ manageTypeopsMap[detail.manageType] }}</div>
        </div>
        <p>位置：{{ detail.detailedAddress }}</p>
        <p>营业时间：{{ detail.openingHours }}</p>
      </div>
    </div>
    <div class="detailBox">
      <div class="item">
        <div class="name">交易笔数</div>
        <div class="num">{{ detail.total }}</div>
      </div>
      <div class="item">
        <div class="name">商品数量</div>
        <div class="num">{{ detail.putawaying }}</div>
      </div>
      <div class="item">
        <div class="name">已核销积分</div>
        <div class="num">￥{{ detail.consumedPonints || 0.0 }}</div>
      </div>
    </div>
    <div class="shopActivityBox" v-if="detail.discountInfo.length > 0">
      <span><img src="./img/liwu.png" alt="" />商铺活动：</span>
      {{ detail.discountInfo }}
    </div>
    <div class="titleBox">我的商品</div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div class="list">
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in shopGoodsList"
          :key="index"
        >
          <div class="item-top">
            <img :src="item.imageUrl" alt="" />
          </div>
          <div class="item-center">{{ item.title }}</div>
          <div class="item-bottom">
            <span>{{ item.pointsPrice }}</span
            >{{ item.goodsClassify == 1 ? "代币" : "代币" }}
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>
<script>
import {
  businessTypeopsMap,
  businessTypeops,
  manageTypeopsMap,
  manageTypeops,
} from "./map.js";
import { detailByIdUrl, getShopGoodsListUrl } from "./api.js";
export default {
  name: "businessDistrictDetail",
  data() {
    return {
      businessTypeopsMap,
      businessTypeops,
      manageTypeopsMap,
      manageTypeops,

      id: null,
      detail: {},
      shopGoodsList: [],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    onLoad() {
      this.getShopGoodsList();
    },
    // 获取商品列表
    async getShopGoodsList() {
      let params = {
        shopId: this.id,
      };
      let res = await this.$axios.get(`${getShopGoodsListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          if (item.imageUrl) {
            item.imageUrl = this.$handleImg(300, 300, item.imageUrl);
          }
          this.shopGoodsList.push(item);
        });
      }
    },
    // 获取商户详情
    async getDetail() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(`${detailByIdUrl}`, { params: params });
      if (res.code === 200) {
        this.detail = res.data;
      }
    },
    // -------------
    toDetail(item) {
      let str = "";
      if (item.goodsClassify == 1) {
        str = "代币商城";
      } else {
        str = "代币商城";
      }
      this.$router.push({
        name: "mallDetail",
        query: { id: item.id, type: str },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  padding: 0 36px;
  padding-top: 34px;
  box-sizing: border-box;
  .shopActivityBox {
    width: 100%;
    padding: 18px 14px 18px 28px;
    box-sizing: border-box;
    background: rgba(255, 249, 241, 0.82);
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #262626;
    span {
      color: #fe9616;
      text-align: center;
      padding-left: 30px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
      }
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /*换行*/
    .item {
      width: 324px;
      height: 428px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      margin-bottom: 22px;
      margin-right: 30px;
      overflow: hidden;
      .item-bottom {
        padding: 0 20px;
        font-size: 28px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        span {
          color: #ffb92d;
        }
      }
      .item-center {
        font-size: 32px;
        color: #333333;
        padding: 28px 20px 8px 20px;
        box-sizing: border-box;
      }
      .item-top {
        width: 100%px;
        height: 288px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .item:nth-child(2n) {
      margin-right: 0;
    }
  }
  .titleBox {
    font-size: 36px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 30px;
  }
  .detailBox {
    width: 100%;
    height: 142px;
    display: flex;
    margin-bottom: 56px;
    .item {
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(236, 236, 236, 0.5);
      border-radius: 4px;
      height: 100%;
      padding-top: 26px;
      box-sizing: border-box;
      text-align: center;
      .name {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.25);
        margin-bottom: 6px;
      }
      .num {
        font-size: 38px;
        font-weight: 600;
        color: #fe9616;
      }
    }
    .item:nth-child(2) {
      margin: 0 18px;
    }
  }
  .infoBox {
    display: flex;
    width: 100%;
    height: 112px;
    margin-bottom: 36px;
    .right_box {
      flex: 1;
      padding-left: 30px;
      box-sizing: border-box;
      p {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .name {
          font-size: 32px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-right: 28px;
        }
        .type {
          font-size: 24px;
          color: #fe9616;
          background: #fff4e7;
          border-radius: 6px;
          border: 2px solid #fe9616;
          padding: 0 12px;
          box-sizing: border-box;
        }
      }
    }
    .left_box {
      width: 112px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
